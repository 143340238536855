import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { getAssignments } from '../../actions/user';
import { ToastsStore } from 'react-toasts';
import LoadingOverlay from 'react-loading-overlay';
import { IMG_ROOT } from "../../config";
import Background from '../../assets/images/page-title.jpg';
import $ from "jquery";
require('./pricing.css');

class Price extends Component {
  state = {
    isLoading: false,
    selectedCatId: 1,
    selectedSerId: 1,
  }

  constructor(props) {
    super(props);
    this.getAssignmentByService = this.getAssignmentByService.bind(this);
    this.getAssignmentByCategory = this.getAssignmentByCategory.bind(this);
  }


  componentWillMount() {

  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.props.getAssignments().then(() => {
      this.setState({ isLoading: false, selectedSerId: this.props.assignments.selectedSerId, selectedCatId: this.props.assignments.selectedCatId });
    }).catch((e) => {
      ToastsStore.error(<span><b>Sorry ! </b><i>something went wrong.</i></span>);
      this.setState({ isLoading: false });
    })
  }

  getAssignmentByService(serviceId) {
    this.setState({ isLoading: true });
    this.props.getAssignments({ "service_id": serviceId, "category_id": this.state.selectedCatId }).then(() => {
      this.setState({ isLoading: false, selectedSerId: this.props.assignments.selectedSerId });
      $('.list-group-item, .list-group-item > a ').removeClass('active');
      $('#serv' + serviceId).addClass('active');
      $('#serv' + serviceId + ' > a').addClass('active');
    }).catch((e) => {
      ToastsStore.error(<span><b>Sorry ! </b><i>something went wrong.</i></span>);
      this.setState({ isLoading: false });
    })
  }

  getAssignmentByCategory(catId) {
    this.setState({ isLoading: true });
    this.props.getAssignments({ "category_id": catId, "service_id": this.state.selectedSerId }).then(() => {
      this.setState({ isLoading: false, selectedCatId: this.props.assignments.selectedCatId });
      $('.category-tab > a ').removeClass('active');
      $('#catId' + catId + ' > a').addClass('active');
    }).catch((e) => {
      ToastsStore.error(<span><b>Sorry ! </b><i>something went wrong.</i></span>);
      this.setState({ isLoading: false });
    })
  }

  render() {
    const { assignments, services, categories, selectedSerId, selectedCatId } = this.props.assignments;
    if (assignments == undefined) {
      return (<div className="main-container"><section className="loader-container centred wow slideInUp animated" style={{ backgroundImage: `url(${Background})` }}>
        <div className="loader-box">
          <LoadingOverlay
            active={this.state.isLoading}
            spinner
            text='Please wait...'
          ></LoadingOverlay>
        </div>
      </section></div>)
    } else {
      return (
        <div>
          <section className="page-title centred wow slideInUp animated" style={{ backgroundImage: `url(${Background})` }}>
            <div className="container">
              <div className="content-box">
                <div className="title"><h2>Pricing</h2></div>
              </div>
            </div>
          </section>
          <section style={{ minHeight: "400px" }} className="about-section">
            <div className="container">
              <div className="row">
                <div className="col-md-2 pull-left service-list">
                  <ul className="list-group">
                    {services.map((service, i) => {
                      return (
                        <li key={i} id={"serv" + service.id} className={(service.id === selectedSerId) ? "active list-group-item btn btn-sm" : "list-group-item btn btn-sm"}>
                          <a onClick={() => this.getAssignmentByService(service.id)} className={(service.id === selectedSerId) ? 'active ser-btn' : 'ser-btn'} href="javascript:void(0)">{service.name}</a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="col-md-10 pull-left">
                  <table className="table table-stripped">
                    <thead>
                      <tr className="primary" bgcolor="#337ab7">
                        {categories.map((category, i) => {
                          return (<th key={i} id={"catId" + category.id} className="text-left category-tab text-uppercase"><a onClick={() => this.getAssignmentByCategory(category.id)} href="javascript:void(0)" className={(category.id == selectedCatId) ? 'active btn cat-btn btn-default' : 'btn cat-btn btn-default'}> {category.name}</a></th>)
                        })}
                      </tr>
                    </thead>
                  </table>
                </div>
                <div className="col-md-10 pull-left">
                  <table className="table table-bordered">
                    <tbody className="table-body">
                      <tr className="warning text-uppercase"><th>Product Image</th><th>Product Name</th><th>Price</th></tr>
                      {assignments.map((assignment, i) => {
                        return (
                          <tr className="tbody" key={i}>
                            <td><img className="product-icon" src={IMG_ROOT + "products/" + assignment.product.image} /></td>
                            <td>{assignment.product.name}</td>
                            <td><strike style={{ color: "red" }}><span className="actual-price">{assignment.price}</span></strike>/{assignment.offer_price}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    assignments: state.userReducer.result
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getAssignments
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Price));