import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from "jquery";
import '../../App.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { BaseUrl } from '../../config';
import Logo from '../../assets/images/logo.png';
export class Header extends Component {

  constructor(props){
    super(props)
  }

  componentWillMount() {

  }
  
  handleLogout(event) {
    localStorage.clear();
    event.preventDefault();
    window.location.href = `${BaseUrl}login`;
  }

  componentDidMount(){
    if (localStorage.getItem('isLoggedIn')) {
      $('#loggedUserName').html(JSON.parse(localStorage.getItem('user')).name);
    }
  }

  render() {
    return (
      <header className="main-header">
        <div className="header-top">
          <div className="container">
            <div className="logo-box">
              <Link to="/"></Link>
            </div>
            <div className="header-info">
              <div className="single-info">
                <div className="icon-box"><i className="fa fa-envelope"></i></div>
                <div className="text">infoyourmail@gmail.com</div>
              </div>
              <div className="single-info">
                <div className="icon-box"><i className="fa fa-phone"></i></div>
                <div className="text">Wants a launder? <br />+91 9911031358</div>
              </div>
              <div id="not-logged-in" className="top-btn"><Link to="login">Login</Link>  <Link to="register">Register</Link>  <Link to={"checkout"}><i id="headerCart" className="fa fa-shopping-cart"></i></Link></div>
              <div id="logged-in" className="top-btn"><Link id="loggedUserName" to="my-account"></Link>  <Link to="login" onClick={this.handleLogout} >Logout</Link>  <Link to={"checkout"}><i className="fa fa-shopping-cart"></i></Link></div>
            </div>
          </div>
        </div>
        <div className="theme_menu stricky slideIn animated">
          <div className="container">
            <div className="menu-area">
              <nav className="main-menu">
                <div className="navbar-header">
                  <button type="button" className="navbar-toggle" data-toggle="collapse"
                    data-target=".navbar-collapse">
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                </div>
                <div className="navbar-collapse collapse clearfix">
                  <ul className="navigation clearfix">
                    <li className="current"><Link to="/">Home</Link></li>
                    <li><Link to="about">About Us</Link></li>
                    <li><Link to="pricing">Pricing</Link></li>
                    <li><Link to="services">Services</Link></li>
                    <li><Link to="contact">Contact Us</Link></li>
                  </ul>
                  <ul className="mobile-menu clearfix">
                    <li className="current"><Link to="/">Home</Link></li>
                    <li><Link to="about">About Us</Link></li>
                    <li><Link to="pricing">Pricing</Link></li>
                    <li className="dropdown"><Link to="services">Services</Link></li>
                    <li><Link to="contact">Contact Us</Link></li>
                  </ul>
                </div>
              </nav>
              <div className="search-box-area">
                <div className="search-toggle"><i className="fa fa-search"></i></div>
                <div className="search-box">
                  <form method="post" action="">
                    <div className="form-group">
                      <input type="search" name="search" placeholder="Search Here" required="" />
                      <button type="submit"><i className="fa fa-search"></i></button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
  
}
const mapStateToProps = state => {
  return {
    user: state.userReducer.result
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));