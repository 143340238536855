import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import { getOrders, getOrderDetails } from '../../actions/user';
import { ToastsStore } from 'react-toasts';
import LoadingOverlay from 'react-loading-overlay';
import { OrderDetailsModal } from '../common/OrderDetailsModal';
import { AdminUrl } from "../../config";

class Order extends Component {
  state = {
    isLoading: false,
    open: false
  }

  componentWillMount() {
    if (!localStorage.getItem('isLoggedIn')) {
      this.props.history.push("login");
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.props.getOrders().then(() => {
      if (!this.props.orders.is_success) {
        ToastsStore.error(<span><b>Sorry ! </b><i>{this.props.user.message}</i></span>);
      }
      this.setState({ isLoading: false });
    }).catch((e) => {
      ToastsStore.error(<span><b>Sorry ! </b><i>something went wrong.</i></span>);
      this.setState({ isLoading: false });
    })
  }

  getOrderStatus(orderStatus) {
    switch (orderStatus) {
      case 1:
        return 'Placed'
        break;
      case 2:
        return 'Process'
        break;
      case 3:
        return 'Completed'
        break;
      case 4:
        return 'Rejected'
      default:
        return 'Placed'
        break;
    }
  }

  oderDetails = (orderId) => {
    this.props.getOrderDetails(orderId).then(()=>{
      this.setState({ open: true });
    })
  }
  
  downloadInvoice = (orderId) => {
    window.open(`${AdminUrl}order/pdfview/${orderId}`, '_blank');
  }

  render() {
    const { orders } = this.props.orders;
    if (orders === undefined) {
      return (<LoadingOverlay
        active={this.state.isLoading}
        spinner
        text='please wait...'
      ></LoadingOverlay>)
    } else if (orders.length == 0) {
      return (<div className="alert alert-danger text-center">Sorry ! No Order Found.</div>)
    } else {
      return (
        <div>
          <LoadingOverlay
            active={this.state.isLoading}
            spinner
            text='please wait...'
          >
            <table className="table table-bordered">
              <thead>
                <tr className="text-info bg-primary">
                  <th>Order Reference No</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order, i) => {
                  return (
                    <tr className="tbody" key={i}>
                      <td>DH0000{order.id}</td>
                      <td>{order.amount}</td>
                      <td>{this.getOrderStatus(order.status)}</td>
                      <td className="text-center">
                        <a onClick={this.oderDetails.bind(null, order.id)}><i title="View Details" className="fa fa-eye"></i></a> | <a onClick={this.downloadInvoice.bind(null, order.id)}><i title="Download Invoice" className="fa fa-download"></i></a></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </LoadingOverlay>
          <OrderDetailsModal props={this.props} isOpen={this.state.open}></OrderDetailsModal>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    orders: state.userReducer.result,
    orderDetails: state.userReducer.orderDetails,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getOrders,
    getOrderDetails
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Order));