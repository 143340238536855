import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { ToastsStore } from 'react-toasts';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { registerUser } from '../../actions/user';
import $ from "jquery";

class Register extends Component {
  state = {
    name: '',
    email: '',
    password: '',
    phone: '',
    isLoading: false
  }

  componentDidMount() {

  }

  onInputChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'name':
        this.setState({ name: value })
        break;
      case 'email':
        this.setState({ email: value })
        break;
      case 'password':
        this.setState({ password: value })
        break;
      case 'phone':
        this.setState({ phone: value })
        break;
      default:
        break;
    }
  }

  handleSubmit = (e) => {
    this.setState({isLoading:true});
    e.preventDefault();
    let postData = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      phone: this.state.phone
    }
    
    this.props.registerUser(postData).then(() => {
      if (this.props.user.is_success) {
        let userInfo = this.props.user;
        ToastsStore.success(<span><b>Thank you ! </b><i>{this.props.user.message}</i></span>);
        setTimeout(() => {
          this.setState({isLoading:false});
          this.props.history.push("login");
        }, 3000);
      } else {
        ToastsStore.error(<span><b>Sorry ! </b><i>{this.props.user.message}</i></span>);
        this.setState({isLoading:false});
      }
    }).catch((e) => {
      ToastsStore.error(<span><b>Sorry ! </b><i>something went wrong.</i></span>);
      this.setState({isLoading:false});
    });
  }

  render() {
    return (
      <div>
        <section className="contact-section sp-one">
          <div className="container">
            <div className="row">
              <div className="col-md-offset-3 col-md-6 col-sm-12 col-xs-12 contact-column wow slideInRight animated">
                <LoadingOverlay
                  active={this.state.isLoading}
                  spinner
                  text='please wait...'
                >
                  <div className="contact-form-area">
                    <h3 className="text-center text-uppercase">Register Now</h3>
                    
                    <form onSubmit={this.handleSubmit} method="POST" id="contact-form" className="default-form">
                      <div className="row">
                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                          <input type="text" name="name" className="form-control" onChange={this.onInputChange} placeholder="User Name" required />
                        </div>
                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                          <input type="email" name="email" className="form-control" onChange={this.onInputChange} placeholder="Email Address" required />
                        </div>
                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                          <input type="password" name="password" className="form-control" onChange={this.onInputChange} placeholder="Password" required />
                        </div>
                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                          <input type="text" name="phone" className="form-control" minLength="10" onChange={this.onInputChange} placeholder="Mobile Number" required />
                        </div>
                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                          <button type="submit" className="btn btn-primary btn-lg btn-block" name="submit-form">Register</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </LoadingOverlay>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.userReducer.result
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    registerUser
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Register));