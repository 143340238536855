import React, { Component } from 'react';
import Background from '../../assets/images/page-title.jpg';
import img1 from '../../assets/images/content-page/1.jpg';
import img2 from '../../assets/images/content-page/2.jpg';
import img3 from '../../assets/images/content-page/3.jpg';
import LoadingOverlay from 'react-loading-overlay';
import { ToastsStore } from 'react-toasts';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { getPageContent } from '../../actions/user';
class About extends Component {
  state = {
    pageContent:{}
  }
  componentDidMount(){
    this.props.getPageContent('about-us').then(()=>{
      this.setState({pageContent:this.props.content.data})
    })
  }
  render() {
    const { title, description } = this.state.pageContent;
    return (
      <div>
        <section className="page-title centred wow slideInUp animated" style={{ backgroundImage: `url(${Background})` }}>
          <div className="container">
            <div className="content-box">
              <div className="title"><h2>About Us</h2></div>
            </div>
          </div>
        </section>
        <section className="about-section">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-8 col-xs-12">
                <div className="img-box-one img-box wow slideInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" ><figure><img src={img1} alt="" /></figure></div>
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12">
                <div className="img-box-two img-box wow slideInRight animated" data-wow-delay="0ms" data-wow-duration="1500ms" ><figure><img src={img2} alt="" /></figure></div>
              </div>
              <div className="col-md-6 col-sm-12 col-xs-12">
                <div className="about-content">
                  <h3>{title}</h3>
                  <div className="text">
                    <p>{description}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 col-sm-6 col-xs-12">
                <div className="img-box-three img-box wow slideInUp animated" data-wow-delay="0ms" data-wow-duration="1500ms" ><figure><img src={img3} alt="" /></figure></div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { content:state.userReducer.result }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getPageContent
  }, dispatch)
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(About))