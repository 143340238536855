import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../App.css';
import Background from '../../assets/images/bg.jpg';
import Logo from '../../assets/images/logo_new.png';
import footerPrt from '../../assets/images/5(1).png';

export class Footer extends Component {
  render() {
    return (
      <footer className="main-footer overlay-bg" style={{ backgroundImage: `url(${Background})` }}>
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-6 col-xs-12 footer-column">
                <div className="logo-widget footer-widget">
                  <div className="footer-logo"><Link to="/"><img
                    src={Logo} alt="" /></Link></div>
                  <div className="text white">Keep up a vital separation from the hussle of Washing Cloths. Book your Laundry in
                  your general region with AD Laundry.
                          </div>
                 
                  {/* <ul className="footer-social in-block">
                    <li><a href="http://azim.commonsupport.com/Watertown/index.html#"><i
                      className="fa fa-facebook"></i></a></li>
                    <li><a href="http://azim.commonsupport.com/Watertown/index.html#"><i
                      className="fa fa-twitter"></i></a></li>
                    <li><a href="http://azim.commonsupport.com/Watertown/index.html#"><i
                      className="fa fa-linkedin"></i></a></li>
                    <li><a href="http://azim.commonsupport.com/Watertown/index.html#"><i
                      className="fa fa-rss"></i></a></li>
                  </ul> */}
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12 footer-column">
                <div className="service-widget footer-widget">
                  <div className="footer-title">
                    <h4>Services</h4>
                  </div>
                  <ul className="list">
                    <li><Link to="/">Laundry</Link></li>
                    <li><Link to="/">Dry Cleaning</Link></li>
                    <li><Link to="/">Steam Iron</Link></li>
                    <li><Link to="/">Carpet Care</Link></li>
                    <li><Link to="/">Shoe Spa</Link></li>
                  </ul>
                </div>
              </div>
              
             
            </div>
          </div>
        </div>
        <div className="footer-bottom centred">
          <div className="container">
            <div className="container">
              <div className="copyright">© 2021 <Link to="/">adlaundry.in</Link>. All Rights
                      Reserved.</div>
            </div>
          </div>
        </div>
        <div className="bubbleContainer">
          <div className="bubble-1"></div>
          <div className="bubble-2"></div>
          <div className="bubble-3"></div>
          <div className="bubble-4"></div>
          <div className="bubble-5"></div>
          <div className="bubble-6"></div>
          <div className="bubble-7"></div>
          <div className="bubble-8"></div>
          <div className="bubble-9"></div>
          <div className="bubble-10"></div>
          <div className="bubble-11"></div>
          <div className="bubble-12"></div>
          <div className="bubble-13"></div>
          <div className="bubble-14"></div>
          <div className="bubble-15"></div>
        </div>
      </footer>
    );
  }
}