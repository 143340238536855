import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { getLoggedUser, updateProfile } from '../../actions/user';
import LoadingOverlay from 'react-loading-overlay';
import { ToastsStore } from 'react-toasts';

class Profile extends Component {
  state = {
    name: '',
    password: '',
    phone: '',
    isLoading: false
  }

  constructor(props) {
    super(props);
  }

  componentWillMount() {
    if (!localStorage.getItem('isLoggedIn')) {
      this.props.history.push("login");
    }
  }


  onInputChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'name':
        this.setState({ name: value })
        break;
      case 'password':
        this.setState({ password: value })
        break;
      case 'phone':
        this.setState({ phone: value })
        break;
      default:
        break;
    }
  }

  handleSubmit = (e) => {
    this.setState({ isLoading: true });
    e.preventDefault();
    let postData = {
      name: this.state.name,
      password: this.state.password,
      phone: this.state.phone
    }

    this.props.updateProfile(postData).then(() => {
      if (this.props.loggedUser.is_success) {
        ToastsStore.success(<span><b>Thank you ! </b><i>{this.props.loggedUser.message}</i></span>);
        setTimeout(() => {
          this.setState({ isLoading: false });
        }, 3000);
      } else {
        ToastsStore.error(<span><b>Sorry ! </b><i>{this.props.loggedUser.message}</i></span>);
        this.setState({ isLoading: false });
      }
    }).catch((e) => {
      ToastsStore.error(<span><b>Sorry ! </b><i>something went wrong.</i></span>);
      this.setState({ isLoading: false });
    });
  }

  componentDidMount() {
    this.setState({isLoading:true});
    this.props.getLoggedUser().then(() => {
      const { loggedUser } = this.props;
      this.setState({name:loggedUser.name, phone:loggedUser.phone})
      this.setState({isLoading:false});
    })
  }

  render() {
    const { loggedUser } = this.props;
    return (
      <div>
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text='please wait...'
        >
          <form onSubmit={this.handleSubmit} method="POST" id="contact-form" className="default-form">
            <div className="row">
              <div className="form-group col-md-12 col-sm-12 col-xs-12">
                <input type="text" name="name" value={this.state.name} className="form-control" onChange={this.onInputChange} placeholder="User Name" required />
              </div>
              <div className="form-group col-md-12 col-sm-12 col-xs-12">
                <input type="password" name="password" value={this.state.password} className="form-control" onChange={this.onInputChange} placeholder="Password" />
              </div>
              <div className="form-group col-md-12 col-sm-12 col-xs-12">
                <input type="text" name="phone" value={this.state.phone} className="form-control" onChange={this.onInputChange} placeholder="Phone" required />
              </div>
              <div className="form-group col-md-12 col-sm-12 col-xs-12">
                <button type="submit" className="btn btn-primary btn-lg btn-block" name="submit-form">Update Profile</button>
              </div>
            </div>
          </form>
        </LoadingOverlay>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loggedUser: state.userReducer.result
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getLoggedUser,
    updateProfile
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));