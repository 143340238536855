class ProductAPI {
    static  getProduct = async() => {
        try {
            const products = await fetch('https://reqres.in/api/users/2');
            return await products.json();
            
        } catch (e) {
            return (e)
            
        }
    }
}
export default ProductAPI;