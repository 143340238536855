const initialState = {
  product:{},
  isFetching:true,
  isData:false,
  result:[]
}

const productReducer = (state = initialState, action) => {
  const newState=Object.assign({}, state);
    switch (action.type) {
      case 'GET_PRODUCTS':
       newState.product = action.products;
       return newState;
      case 'ADD_PRODUCT':
        return [
          ...state,
          {
            id: action.id,
            text: action.text,
            completed: false
          }
        ]
      case 'TOGGLE_PRODUCT':
        return state.map(todo =>
          todo.id === action.id ? { ...todo, completed: !todo.completed } : todo
        )
      default:
        return state;
    }
  }
  
  export default productReducer;