import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  GET_ORDER_REQUEST,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAILURE,
  GET_ASSIGNMENT_REQUEST,
  GET_ASSIGNMENT_SUCCESS,
  GET_ASSIGNMENT_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  GET_PAGE_REQUEST,
  GET_PAGE_SUCCESS,
  GET_PAGE_FAILURE,
  CONTACT_REQUEST,
  CONTACT_SUCCESS,
  CONTACT_FAILURE,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  GET_ORDER_DETAILS_REQUEST,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_FAILURE,
  GET_CONFIG_SUCCESS,
  GET_CONFIG_FAILURE,
  GET_CONFIG_REQUEST
} from '../actions/constants';

const initialState = {
  result: [],
  orderDetails:[],
  isFetching: true,
  isSuccess: false,
  isAuthenticated: false
}

const userReducer = (state = initialState, action) => {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state, isFetching: true, isAuthenticated: false };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: action.userData.is_success,
        result: action.userData
      };
    case LOGIN_FAILURE:
      return { ...state, isFetching: false, isAuthenticated: false };
    case REGISTER_REQUEST:
      return { ...state, isFetching: true, isAuthenticated: false };
    case REGISTER_SUCCESS:
      return {
          ...state,
          isFetching: false,
          isAuthenticated: action.userData.is_success,
          result: action.userData
        };
    case REGISTER_FAILURE:
      return { ...state, isFetching: false, isAuthenticated: false };
    case CONTACT_REQUEST:
      return { ...state, isFetching: true, isAuthenticated: false };
    case CONTACT_SUCCESS:
      return {
          ...state,
          isFetching: false,
          isAuthenticated: action.userData.is_success,
          result: action.userData
        };
    case CONTACT_FAILURE:
      return { ...state, isFetching: false, isAuthenticated: false };
    case GET_PAGE_REQUEST:
      return { ...state, isFetching: true, isAuthenticated: false };
    case GET_PAGE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: action.userData.is_success,
        result: action.userData
      };
    case GET_PAGE_FAILURE:
      return { ...state, isFetching: false, isAuthenticated: false };
    case GET_ORDER_REQUEST:
      return { ...state, isFetching: true, isAuthenticated: false };
    case GET_ORDER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: action.userData.is_success,
        result: action.userData
      };
    case GET_ORDER_FAILURE:
      return { ...state, isFetching: false, isAuthenticated: false };
    case GET_ASSIGNMENT_REQUEST:
      return { ...state, isFetching: true, isSuccess: false };
    case GET_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isSuccess: action.userData.is_success,
        result: action.userData
      };
    case GET_ASSIGNMENT_FAILURE:
      return { ...state, isFetching: false, isSuccess: false };
    case GET_PROFILE_REQUEST:
      return { ...state, isFetching: true, isAuthenticated: false };
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: action.userData.is_success,
        result: action.userData
      };
    case GET_PROFILE_FAILURE:
      return { ...state, isFetching: false, isAuthenticated: false };
    case UPDATE_PROFILE_REQUEST:
      return { ...state, isFetching: true, isAuthenticated: false };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: action.userData.is_success,
        result: action.userData
      };
    case UPDATE_PROFILE_FAILURE:
      return { ...state, isFetching: false, isAuthenticated: false };
    case GET_ORDER_DETAILS_REQUEST:
      return { ...state, isFetching: true, isAuthenticated: false };
    case GET_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: action.userData.is_success,
        orderDetails: action.userData
      };
    case GET_ORDER_DETAILS_FAILURE:
      return { ...state, isFetching: false, isAuthenticated: false };
    case GET_CONFIG_REQUEST:
      return { ...state, isFetching: true, isAuthenticated: false };
    case GET_CONFIG_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: action.userData.is_success,
        configDetails: action.userData
      };
    case GET_CONFIG_FAILURE:
      return { ...state, isFetching: false, isAuthenticated: false };
    default:
      return state;
  }
}

export default userReducer;