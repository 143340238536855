import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Background from '../../assets/images/page-title.jpg';
import LoadingOverlay from 'react-loading-overlay';
import { ToastsStore } from 'react-toasts';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { contactRequest } from '../../actions/user';
class Contact extends Component {
  state = {
    name:'',
    email:'',
    subject:'',
    mobile:'',
    message:''
  }

  onInputChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'name':
        this.setState({ name: value })
        break;
      case 'email':
        this.setState({ email: value })
        break;
      case 'subject':
        this.setState({ subject: value })
        break;
      case 'mobile':
        this.setState({ mobile: value })
        break
      case 'message':
        this.setState({ message: value })
        break;
      default:
        break;
    }
  }

  handleSubmit = (e) => {
    this.setState({isLoading:true});
    e.preventDefault();
    let postData = {
      name: this.state.name,
      email: this.state.email,
      subject: this.state.subject,
      mobile: this.state.mobile,
      message: this.state.message
    }
    console.log(postData)
    
    this.props.contactRequest(postData).then(() => {
      if (this.props.contact.is_success) {
        let userInfo = this.props.user;
        ToastsStore.success(<span><b>Thank you ! </b><i>{this.props.contact.message}</i></span>);
      } else {
        ToastsStore.error(<span><b>Sorry ! </b><i>{this.props.contact.message}</i></span>);
        this.setState({isLoading:false});
      }
    }).catch((e) => {
      ToastsStore.error(<span><b>Sorry ! </b><i>something went wrong.</i></span>);
      this.setState({isLoading:false});
    });
  }

  render() {
    return (
      <div>
        <section className="page-title centred wow slideInUp animated" style={{ backgroundImage: `url(${Background})` }}>
          <div className="container">
            <div className="content-box">
              <div className="title"><h2>Contact Us</h2></div>
            </div>
          </div>
        </section>
        <section className="contact-section sp-one">
          <div className="container">
            
            <div className="row">
              <div className="col-md-5 col-sm-6 col-xs-12 contact-column wow slideInLeft animated">
                <div className="contact-info">
                  {/* <div className="single-item">
                    <h3>Address</h3>
                    <div className="info-box">
                      <div className="icon-box"><i className="fa fa-map-marker"></i></div>
                      <div className="text">PO Box 16122 Collins Street West,Victoria 8007 Australia</div>
                    </div>
                  </div> */}
                  <div className="single-item">
                    <h3>Phone</h3>
                    <div className="info-box">
                      <div className="icon-box"><i className="fa fa-phone"></i></div>
                      <div className="text">+91 9911031358</div>
                      <div className="text">+91 9911031358</div>
                    </div>
                  </div>
                  <div className="single-item">
                    <h3>Mail</h3>
                    <div className="info-box">
                      <div className="icon-box"><i className="fa fa-envelope"></i></div>
                      <div className="text">adlaundry@gmail.com</div>
                      <div className="text">adlaundry@gmail.com</div>
                    </div>
                  </div>
                </div>
                {/* <div className="social-info">
                  <h4>Get Social With Us</h4>
                  <ul className="social in-block">
                    <li><Link to=""><i className="fa fa-facebook"></i></Link></li>
                    <li><Link to=""><i className="fa fa-linkedin"></i></Link></li>
                    <li><Link to=""><i className="fa fa-twitter"></i></Link></li>
                    <li><Link to=""><i className="fa fa-skype"></i></Link></li>
                  </ul>
                </div> */}
              </div>
              <div className="col-md-7 col-sm-8 col-xs-12 contact-column wow slideInRight animated">
                <div className="contact-form-area">
                  <h3>Get In Touch</h3>
                  
                  <form  onSubmit={this.handleSubmit} method="post" action="sendemail.php" id="contact-form" className="default-form">
                    <div className="row">
                      <div className="form-group col-md-6 col-sm-6 col-xs-12">
                        <input type="text" name="name" placeholder="Your Name *" className="form-control" onChange={this.onInputChange} required />
                      </div>
                      <div className="form-group col-md-6 col-sm-6 col-xs-12">
                        <input type="email" name="email" placeholder="Email Address *" className="form-control" onChange={this.onInputChange} required />
                      </div>
                      <div className="form-group col-md-6 col-sm-12 col-xs-12">
                        <input type="text" name="subject" placeholder="Subject" className="form-control" onChange={this.onInputChange} required />
                      </div>
                      <div className="form-group col-md-6 col-sm-12 col-xs-12">
                        <input type="text" name="mobile" placeholder="Phone" className="form-control" onChange={this.onInputChange} required />
                      </div>
                      <div className="form-group col-md-12 col-sm-12 col-xs-12">
                        <textarea placeholder="Your Message" name="message" className="form-control" onChange={this.onInputChange}></textarea>
                      </div>
                      <div className="form-group col-md-12 col-sm-12 col-xs-12">
                        <button type="submit" name="submit-form">Submit</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contact:state.userReducer.result
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    contactRequest
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Contact))