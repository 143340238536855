import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { getAssignments } from '../../actions/user';
import { ToastsStore } from 'react-toasts';
import LoadingOverlay from 'react-loading-overlay';
import { IMG_ROOT } from "../../config";
import Background from '../../assets/images/page-title.jpg';
import Header from '../common/Header';
import $ from "jquery";
require('./services.css');

class Service extends Component {
  state = {
    isLoading: false,
    selectedCatId: 1,
    selectedSerId: 1,
    cartValue:{},
    total:0,
    totalItems:0
  }

  constructor(props) {
    super(props);
    this.getAssignmentByService = this.getAssignmentByService.bind(this);
    this.getAssignmentByCategory = this.getAssignmentByCategory.bind(this);
    this.cartUpdate = this.cartUpdate.bind(this);
  }

  
  componentWillMount() {
    this.updateCartValues();
    this.updateTotal();
  }

  updateTotal(){
    let cart = JSON.parse(localStorage.getItem('cart'));
    let total = 0.00;
    let totalItems = 0;
    for (var p in cart) {
      if(cart.hasOwnProperty(p)) {
        totalItems = (parseInt(totalItems) + parseInt(cart[p].unit));
        total = (parseInt(total) + parseInt(cart[p].unit_price*cart[p].unit))
      }
    }
    this.setState({total:total, totalItems:totalItems});
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.props.getAssignments().then(() => {
      this.setState({ isLoading: false, selectedSerId: this.props.assignments.selectedSerId, selectedCatId: this.props.assignments.selectedCatId });
    }).catch((e) => {
      ToastsStore.error(<span><b>Sorry ! </b><i>something went wrong.</i></span>);
      this.setState({ isLoading: false });
    })
  }

  getAssignmentByService(serviceId) {
    this.setState({ isLoading: true });
    this.props.getAssignments({"category_id": this.state.selectedCatId, "service_id": serviceId }).then(() => {
      this.setState({ isLoading: false, selectedSerId: this.props.assignments.selectedSerId });
      $('.list-group-item, .list-group-item > a ').removeClass('active');
      $('#serv' + serviceId).addClass('active');
      $('#serv' + serviceId + ' > a').addClass('active');
      this.updateCartValues();
    }).catch((e) => {
      ToastsStore.error(<span><b>Sorry ! </b><i>something went wrong.</i></span>);
      this.setState({ isLoading: false });
    })
  }

  getAssignmentByCategory(catId) {
    this.setState({ isLoading: true });
    this.updateCartValues();
    this.props.getAssignments({ "category_id": catId, "service_id": this.state.selectedSerId }).then(() => {
      this.setState({ isLoading: false, selectedCatId: this.props.assignments.selectedCatId });
      $('.category-tab > a ').removeClass('active');
      $('#catId' + catId + ' > a').addClass('active');
    }).catch((e) => {
      ToastsStore.error(<span><b>Sorry ! </b><i>something went wrong.</i></span>);
      this.setState({ isLoading: false });
    })
  }

  updateCartValues(){
    const cart = JSON.parse(localStorage.getItem('cart'));
    if(cart !== null){
      this.state.cartValue = cart;
    }
  }

  cartUpdate(event, assId, unitPrice, index){
    this.updateCartValues();
    const { name, value } = event.target;
    $('#row'+assId+' .sub-total > span').html(0.00);
    $('#row'+assId+' .sub-total > span').html(unitPrice*value);

    if((assId in this.state.cartValue)){
      this.state.cartValue[assId].unit = value
    }else{
      this.state.cartValue[assId] = {
        "unit_price":unitPrice,
        "unit":parseInt(value),
        "assId":assId,
        "product_name":this.props.assignments.assignments[index].product.name,
        "product_image":this.props.assignments.assignments[index].product.image,
        "service_name":this.props.assignments.assignments[index].service.name,
      }
    }

    localStorage.setItem('cart', JSON.stringify(this.state.cartValue))
    this.updateTotal();
  }

  render() {
    const { assignments, services, categories, selectedSerId, selectedCatId } = this.props.assignments;
    if (assignments == undefined) {
      return (<div className="main-container"><section className="loader-container centred wow slideInUp animated" style={{ backgroundImage: `url(${Background})` }}>
        <div className="loader-box">
          <LoadingOverlay
            active={this.state.isLoading}
            spinner
            text='Please wait...'
          ></LoadingOverlay>
        </div>
      </section></div>)
    } else {
      return (
        <div>
          <section className="page-title centred wow slideInUp animated" style={{ backgroundImage: `url(${Background})` }}>
            <div className="container">
              <div className="content-box">
                <div className="title"><h2>Services</h2></div>
              </div>
            </div>
          </section>
          <section style={{ minHeight: "400px" }} className="about-section">
            <div className="container">
              <div className="row">
                <div className="col-md-2 pull-left service-list">
                  <ul className="list-group">
                    {services.map((service, i) => {
                      return (
                        <li key={i} id={"serv" + service.id} className={(service.id === selectedSerId) ? "active list-group-item btn btn-sm" : "list-group-item btn btn-sm"}>
                          <a onClick={() => this.getAssignmentByService(service.id)} className={(service.id === selectedSerId) ? 'active ser-btn' : 'ser-btn'} href="javascript:void(0)">{service.name}</a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="col-md-10 pull-left">
                  <table className="table table-stripped">
                    <thead>
                      <tr className="primary" bgcolor="#337ab7">
                        {categories.map((category, i) => {
                          return (<th key={i} id={"catId" + category.id} className="text-left category-tab text-uppercase"><a onClick={() => this.getAssignmentByCategory(category.id)} href="javascript:void(0)" className={(category.id == selectedCatId) ? 'active btn cat-btn btn-default' : 'btn cat-btn btn-default'}> {category.name}</a></th>)
                        })}
                        <th className="cart-final-tab"><span className="total-items btn btn-default">{this.state.totalItems}</span></th>
                        <th className="cart-final-tab"><span className="total btn btn-default">RS. {this.state.total}</span></th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div className="col-md-10 pull-left">
                  <table className="table table-bordered">
                    <tbody className="table-body">
                      <tr className="warning text-uppercase"><th>Product Image</th><th>Product Name</th><th>Price</th><th># of Items</th><th>Sub Total</th></tr>
                      {assignments.map((assignment, i) => {
                        return (
                          <tr className="tbody" id={"row"+assignment.id} key={i}>
                            <td><img className="product-icon" src={IMG_ROOT + "products/" + assignment.product.image} /></td>
                            <td>{assignment.product.name}</td>
                            <td><strike style={{ color: "red" }}><span className="actual-price">{assignment.price}</span></strike>/{assignment.offer_price}</td>
                            <td><input min="0" type="number" name="productUnit" value={(this.state.cartValue[assignment.id] === undefined)?0:this.state.cartValue[assignment.id].unit} onChange={(e)=>{this.cartUpdate(e, assignment.id, assignment.offer_price, i)}} className="form-control item-count-input" /></td>
                            <td><span className="sub-total">RS. <span>{(this.state.cartValue[assignment.id] === undefined)?0.00:this.state.cartValue[assignment.id].unit*this.state.cartValue[assignment.id].unit_price}</span></span></td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row text-right checkout-btn"><Link to={"checkout"} className="btn btn-primary" >Proceed To Checkout</Link></div>
            </div>
          </section>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    assignments: state.userReducer.result
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getAssignments
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Service));