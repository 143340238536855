import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import $ from "jquery";
import Profile from "../profile";
import Order from "../order";

class Account extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    isOrder: true,
    isProfile: false,
    request_url:'/my-account',
  }

  componentWillMount() {
    if (!localStorage.getItem('isLoggedIn')) {
      this.props.history.push("login");
    }
  }

  componentDidMount() {

  }

  getDetails(sectionName, event) {
    this.setState({ isOrder: false, isProfile: false });
    switch (sectionName) {
      case 'order':
        this.setState({ isOrder: true, request_url:'/my-account' });
        break;
      case 'profile':
        this.setState({ isProfile: true, request_url:'/profile' });
        break;
    }
    event.preventDefault();
  }

  handleLogout = async (event) => {
    localStorage.clear();
    $('#logged-in').hide();
    $('#not-logged-in').show();
    this.props.history.push("login");
    event.preventDefault();
  }

  getSectionDetails() {
    if (this.state.isOrder) {
      return (<Order></Order>)
    } else if (this.state.isProfile) {
      return (<Profile></Profile>)
    }
  }

  render() {
    return (
      <div>
        <section className="contact-section sp-one">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12 contact-column wow slideInRight animated">
                <div className="col-md-12">
                  <h3 className="text-uppercase">My Account</h3>
                </div>
                <hr></hr>
                <div className="col-md-4">
                  <ul className="list-group">
                    <li className={(this.state.request_url == '/my-account')?'list-group-item active':'list-group-item'}><Link to="#" data-id="order" onClick={this.getDetails.bind(this, 'order')} >My Orders</Link></li>
                    <li className={(this.state.request_url == '/profile')?'list-group-item active':'list-group-item'}><Link to="#" data-id="profile" onClick={this.getDetails.bind(this, 'profile')}>My Profile</Link></li>
                    <li className="list-group-item"><Link to="#" onClick={this.handleLogout}>Logout</Link></li>
                  </ul>
                </div>
                <div className="col-md-8">
                  {this.getSectionDetails()}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.userReducer.result
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({

  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Account));