import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import $ from "jquery";
import { Header } from "./components/common/Header";
import { Footer } from './components/common/Footer';
import About from "./components/about/index";
import Contact from "./components/contact/index";
import Home from "./components/home/index";
import Login from "./components/login";
import Register from "./components/register";
import Account from "./components/account";
import Pricing from "./components/pricing";
import Service  from "./components/services";
import Checkout from "./components/checkout";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
class App extends Component {

  componentWillMount() {

  }

  handleScroll() {
    if ($('.stricky').length) {
      var strickyScrollPos = 100;
      if ($(window).scrollTop() > strickyScrollPos) {
        $('.stricky').removeClass('fadeIn animated');
        $('.stricky').addClass('stricky-fixed fadeInDown animated');
        $('.scroll-to-top').fadeIn(500);
      }
      else if ($(this).scrollTop() <= strickyScrollPos) {
        $('.stricky').removeClass('stricky-fixed fadeInDown animated');
        $('.stricky').addClass('slideIn animated');
        $('.scroll-to-top').fadeOut(500);
      }
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    if (localStorage.getItem('isLoggedIn')) {
      $('#logged-in').show();
      $('#not-logged-in').hide();
    } else {
      $('#logged-in').hide();
      $('#not-logged-in').show();
    }

  }

  render() {
    return (
      <div className="App">
        <ToastsContainer store={ToastsStore} className="toast-notification" position={ToastsContainerPosition.TOP_RIGHT} lightBackground={true} />
        <Router basename='/'>
          <Header />
          <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/contact' component={Contact} />
            <Route path='/pricing' component={Pricing} />
            <Route path='/services' component={Service} />
            <Route path='/checkout' component={Checkout} />
            <Route path='/about' component={About} />
            <Route path='/login' component={Login} />
            <Route path='/register' component={Register} />
            <Route path='/my-account' component={Account} />
          </Switch>
          <Footer />
        </Router>
      </div>
    );
  }
}

export default App;
