import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import Background from '../../assets/images/page-title.jpg';
import { getConfigration } from '../../actions/user';
import { IMG_ROOT } from "../../config";
class Checkout extends Component {

  state = {
    isLoading: false,
    selectedCatId: 1,
    selectedSerId: 1,
    cartValue: [],
    total: 0,
    totalItems: 0,
    deliveryCharge: 0,
    tax: 0
  }

  componentWillMount() {
    this.props.getConfigration().then(()=>{
      this.setState({deliveryCharge:this.props.configDetails.config.payment_info.delivery_charge, tax:this.props.configDetails.config.payment_info.tax})
      this.updateTotal();
    })
  }

  updateTotal() {
    let cart = JSON.parse(localStorage.getItem('cart'));
    let total = 0.00;
    let totalItems = 0;
    let tempArr = []
    for (var p in cart) {
      if (cart.hasOwnProperty(p)) {
        totalItems = (parseInt(totalItems) + parseInt(cart[p].unit));
        total = (parseInt(total) + parseInt(cart[p].unit_price * cart[p].unit))
        tempArr.push(cart[p])
      }
    }
    this.setState({ total: total, totalItems: totalItems, cartValue: tempArr });
  }

  render() {
    if (this.state.cartValue.length == 0) {
      return (<div>
        <section className="page-title centred wow slideInUp animated" style={{ backgroundImage: `url(${Background})` }}>
          <div className="container">
            <div className="content-box">
              <div className="title"><h2>Checkout</h2></div>
            </div>
          </div>
        </section>
        <section style={{ minHeight: "400px" }} className="about-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 pull-left">
                <div className="alert alert-danger text-center"> Your cart is empty.</div>
                <div className="text-center"><Link className="btn btn-primary" to={"services"}>Add Your Service</Link></div>
              </div>
            </div>
          </div>
        </section>
      </div>)
    } else {
      return (
        <div>
          <section className="page-title centred wow slideInUp animated" style={{ backgroundImage: `url(${Background})` }}>
            <div className="container">
              <div className="content-box">
                <div className="title"><h2>Checkout</h2></div>
              </div>
            </div>
          </section>
          <section style={{ minHeight: "400px" }} className="about-section">
            <div className="container">
              <div className="row">
                <div className="col-md-12 pull-left">
                  <table className="table table-bordered">
                    <tbody className="table-body">
                      <tr className="warning text-uppercase">
                        <th>Product Image</th>
                        <th>Product Name</th>
                        <th>Service Name</th>
                        <th>Quntity</th>
                        <th>Unit Price</th>
                        <th>Sub Total (in Rs.)</th>
                      </tr>
                      {this.state.cartValue.map((val, i) => {
                        return (<tr className="tbody" key={i}><td><img className="product-icon" src={IMG_ROOT + "products/" + val.product_image} /></td>
                          <td>{val.product_name}</td>
                          <td>{val.service_name}</td>
                          <td>{val.unit}</td>
                          <td>{val.unit_price}</td>
                          <td>{val.unit_price * val.unit}</td></tr>)
                      })}
                      <tr><td className="text-right" colSpan="5">Total</td><td>{this.state.total}</td></tr>
                      <tr><td className="text-right" colSpan="5">Picup & Delivery Charges</td><td>{this.state.deliveryCharge}</td></tr>
                      <tr><td className="text-right" colSpan="5">Tax</td><td>{this.state.tax}</td></tr>
                      <tr><td className="text-right" colSpan="5">Payable Amount</td><td>{this.state.total + this.state.deliveryCharge + this.state.tax}</td></tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row text-right checkout-btn"><Link to={""} className="btn btn-primary" >Pay Now</Link></div>
            </div>
          </section>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    configDetails: state.userReducer.configDetails,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getConfigration,
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Checkout));