// import * as actionTypes from './actionTypes';
import ProductAPI from "../api/productapi";
export const addProduct = text => ({
    type: 'ADD_PRODUCR',
    text
})
  
export const getProducts = (products) => {
    return {
        type:'GET_PRODUCTS', 
        text:'test demo  sdfsf dsf sdf sdfsd product',
        products:products.data
    }
}

export const getAllProducts= () =>{
    return async dispatch => {
        try {
            const prod=await ProductAPI.getProduct(); 
            dispatch(getProducts(prod))
        } catch (e) {
            throw(e)
        }
    }
}