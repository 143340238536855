import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from "jquery";
import '../../App.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Background from '../../assets/images/page-title.jpg';
import LoadingOverlay from 'react-loading-overlay';
var Modal = require('react-bootstrap-modal')
export class OrderDetailsModal extends Component {
  state = {
    open: false,
    isLoading: false
  }
  constructor(props) {
    super(props)
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({ open: nextProps.isOpen })
  }

  closeModal = () => this.setState({ open: false })

  getOrderStatus(orderStatus) {
    switch (orderStatus) {
      case 1:
        return 'Placed'
        break;
      case 2:
        return 'Process'
        break;
      case 3:
        return 'Completed'
        break;
      case 4:
        return 'Rejected'
      default:
        return 'Placed'
        break;
    }
  }

  getItems(orderItems) {
    orderItems.map((item, i) => {
      return (<tr key={i}><td>{item.ass_id}</td><td>{item.ass_id}</td><td>{item.ass_id}</td><td>{item.ass_id}</td></tr>)
    })
  }

  render() {
    const { orderDetails } = this.props.props;
    let total = 0;
    if (typeof (orderDetails) == 'object' && orderDetails.order_details !== undefined) {
      return (
        <Modal
          show={this.state.open}
          onHide={this.closeModal}
          aria-labelledby="ModalHeader"
        >
          <Modal.Header closeButton>
            <Modal.Title id='ModalHeader'>ORDER DETAILS <span className="pull-right"># Invoice : {orderDetails.prefix}{orderDetails.order_details.id}</span></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table table-bordered">
              <thead>
                <tr className="bg-primary">
                  <th colSpan="2">Bill To</th><th colSpan="2">Order Information</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Name  </td><td>{orderDetails.user_details.name}</td><td>Invoice ID  </td><td>{orderDetails.prefix}{orderDetails.order_details.id}</td>
                </tr>
                <tr>
                  <td>Address  </td><td>{orderDetails.user_details.address}</td><td>Invoice Date  </td><td>{orderDetails.order_details.created_at}</td>
                </tr>
                <tr>
                  <td>Email  </td><td>{orderDetails.user_details.email}</td><td>Payment Method  </td><td>{orderDetails.order_details.payment_method}</td>
                </tr>
                <tr>
                  <td>Mobile/Phone  </td><td>{orderDetails.user_details.phone}</td><td>Total Items  </td><td>{orderDetails.order_items.length}</td>
                </tr>
                <tr>
                  <td>Status  </td><td colSpan="5"> {this.getOrderStatus(orderDetails.order_details.status)}</td>
                </tr>
              </tbody>
            </table>

            <table className="table table-bordered">
              <thead>
                <tr className="bg-primary">
                  <th>Product Name</th>
                  <th>Unit Price</th>
                  <th>Qty</th>
                  <th>Subtotal</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {orderDetails.order_items.map((item, i) => {
                  total = parseInt(total) + parseInt(item.product_assignment[0].offer_price * item.quantity);
                  return (<tr key={i}><td>{item.product_assignment[0].category.name}</td><td>RS. {item.product_assignment[0].offer_price}</td><td>{item.quantity}</td><td>RS. {item.product_assignment[0].offer_price * item.quantity}</td></tr>)
                })}
                <tr><td align="Right" colSpan="3">Subtotal</td><td align="Right">RS. {total}</td></tr>
                <tr><td align="Right" colSpan="3">Picup & Delivery Charges</td><td align="Right">RS. {orderDetails.delivery_charge}</td></tr>
                <tr><td align="Right" colSpan="3">Net Amount</td><td align="Right">RS. {total + orderDetails.delivery_charge}</td></tr>
                <tr><td align="Right" colSpan="3">Tax</td><td align="Right">RS. {orderDetails.tax}</td></tr>
                <tr><td align="Right" colSpan="3">Payable Amount</td><td align="Right">RS. {total + orderDetails.delivery_charge + orderDetails.tax}</td></tr>
              </tbody>
            </table>

            <table className="table table-bordered">
              <tbody className="table-body">
                <tr className=""><td>Bill To</td><td>{orderDetails.user_details.name}</td><td>Amount Due</td><td align="Right">RS. {total + orderDetails.delivery_charge + orderDetails.tax - orderDetails.order_details.amount_paid}</td></tr>
                <tr className=""><td>Customer ID</td><td>{orderDetails.prefix}{orderDetails.user_details.id}</td><td>Paid Amount</td><td align="Right">RS. {(orderDetails.order_details.amount_paid > 0) ? orderDetails.order_details.amount_paid : 0}</td></tr>
                <tr className=""><td>Invoice Number</td><td>{orderDetails.prefix}{orderDetails.order_details.id}</td><td>Balance Amount</td><td align="Right">RS. {(orderDetails.order_details.amount_paid > 0) ? (total + orderDetails.delivery_charge + orderDetails.tax - orderDetails.order_details.amount_paid) : (orderDetails.order_details.amount_paid > 0) ? orderDetails.order_details.amount_paid : 0}</td></tr>
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Modal.Dismiss className='btn btn-danger'>Close</Modal.Dismiss>
          </Modal.Footer>
        </Modal>
      );
    } else {
      return null
    }
  }

}
const mapStateToProps = state => {
  return {
    user: state.userReducer.result
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({

  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Modal));