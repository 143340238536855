import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllProducts } from '../../actions/product';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
// import $ from "jquery";

import OwlCarousel from 'react-owl-carousel2';
import Background from '../../assets/images/2.jpg';

const options = {
    loop: true,
    margin: 0,
    nav: true,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    active: true,
    smartSpeed: 1000,
    autoplay: true,
    navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 1
        },
        1200: {
            items: 1
        }
    }
};

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = { username: '', hello: [] };
    }

    inputData = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    submitData = (event) => {
        event.preventDefault();
    }

    getAllProd = () => {
        this.props.getAllProducts();
    }

    componentWillMount() {

    }

    render() {
        return (
            <div>
                <section className="main-slider">
                    <div className="container-fluid">
                        <ul className="main-slider-carousel owl-carousel owl-theme slide-nav owl-loaded">
                            <OwlCarousel ref="car" options={options} >
                                <li className="item">
                                    <div className="image"><img src={Background} alt="" /></div>
                                    <div className="slider-caption">
                                        <div className="container">
                                            <div className="title">
                                                <h1>Laundry services</h1>
                                            </div>
                                            <div className="text">Thousands of people trust us to care for their clothes because
                                we provide best types of equipment to get clean and fresh clothes.</div>
                                            <div className="tp-btn">
                                                <a href="/services"
                                                    className="btn-one btn-bg">Learn More</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slide-overlay"></div>
                                </li>
                                <li className="item">
                                    <div className="image"><img src={Background} alt="" /></div>
                                    <div className="slider-caption">
                                        <div className="container">
                                            <div className="title">
                                                <h1>Laundry services</h1>
                                            </div>
                                            <div className="text">Thousands of people trust us to care for their clothes because
                                we provide best types of equipment to get clean and fresh clothes.</div>
                                            <div className="tp-btn">
                                                <a href="/services"
                                                    className="btn-one btn-bg">Learn More</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slide-overlay"></div>
                                </li>
                                <li className="item">
                                    <div className="image"><img src={Background} alt="" /></div>
                                    <div className="slider-caption">
                                        <div className="container">
                                            <div className="title">
                                                <h1>Laundry services</h1>
                                            </div>
                                            <div className="text">Thousands of people trust us to care for their clothes because
                                we provide best types of equipment to get clean and fresh clothes.</div>
                                            <div className="tp-btn">
                                                <a href="/services"
                                                    className="btn-one btn-bg">Learn More</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slide-overlay"></div>
                                </li>
                            </OwlCarousel>
                        </ul>
                    </div>
                </section>
                <section className="working-section sp-one centred">
                    <div className="container">
                        <div className="sec-title">
                            <h2>How <span>AD Laundry</span> Works</h2>
                        </div>
                        <div className="title-text">Keep up a vital separation from the hussle of Washing Cloths. Book your Laundry in
                your general region with<br /> AD Laundry. Book Now and Avail Great Discounts on Your Next Order.</div>
                        <div className="row">
                            <div className="col-md-3 col-sm-6 col-xs-12 working-column">
                                <div className="single-working-content">
                                    <div className="icon-box">
                                        <div className="icon"><i className="flaticon-shipped"></i></div>
                                    </div>
                                    <h3>Free pick up</h3>
                                    <div className="count-text">1</div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-xs-12 working-column">
                                <div className="single-working-content">
                                    <div className="icon-box">
                                        <div className="icon"><i className="flaticon-shirt"></i></div>
                                    </div>
                                    <h3>Wash and dry</h3>
                                    <div className="count-text">2</div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-xs-12 working-column">
                                <div className="single-working-content">
                                    <div className="icon-box">
                                        <div className="icon"><i className="flaticon-iron"></i></div>
                                    </div>
                                    <h3>Fold and iron</h3>
                                    <div className="count-text">3</div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6 col-xs-12 working-column">
                                <div className="single-working-content">
                                    <div className="icon-box">
                                        <div className="icon"><i className="flaticon-transport"></i></div>
                                    </div>
                                    <h3>Free delivery</h3>
                                    <div className="count-text">4</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

// Declare propTypes
// Home.propTypes = {
//     dispatch: PropTypes.func,
//     productData: PropTypes.object
// };

// export default connect(({  }) => {
//     return {
//         // userData: AuthReducer.userData
//     };
// })(Home);
const mapStateToProps = state => {
    // console.log(state.productReducer)
    return {
        product: state.productReducer.product
    }
}

// const mapStateToProps = ({ productReducer }) => {
//     console.log('reducer',productReducer)
//     const { data } = productReducer;
//     return {
//         cRequests: data,
//     };
// };

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getAllProducts
    }, dispatch)
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));