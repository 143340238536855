import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk  from 'redux-thunk';
import rootReducer from './reducers/index';
const logger = store => {
    return next => {
        return action => {
            // console.log('miiddle ware called', action)
            const result = next(action);
            // console.log('[Middleware]', store.getState())
            return result;
        }
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(logger, thunk)))

ReactDOM.render(
<Provider BrowserRouter={BrowserRouter} store={store}>
    <App />
</Provider>, 
document.getElementById('root'));
serviceWorker.unregister();
