import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    GET_ORDER_REQUEST,
    GET_ORDER_SUCCESS,
    GET_ORDER_FAILURE,
    GET_ASSIGNMENT_REQUEST,
    GET_ASSIGNMENT_SUCCESS,
    GET_ASSIGNMENT_FAILURE,
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    REGISTER_FAILURE,
    GET_PAGE_REQUEST,
    GET_PAGE_SUCCESS,
    GET_PAGE_FAILURE,
    CONTACT_REQUEST,
    CONTACT_SUCCESS,
    CONTACT_FAILURE,
    GET_PROFILE_REQUEST,
    GET_PROFILE_SUCCESS,
    GET_PROFILE_FAILURE,
    UPDATE_PROFILE_REQUEST,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAILURE,
    GET_ORDER_DETAILS_REQUEST,
    GET_ORDER_DETAILS_SUCCESS,
    GET_ORDER_DETAILS_FAILURE,
    GET_CONFIG_REQUEST,
    GET_CONFIG_FAILURE,
    GET_CONFIG_SUCCESS
} from './constants';
import userApi from "../api/userApi";

export const loginUser = (postData) => {
    return async dispatch => {
        dispatch({ type: LOGIN_REQUEST });
        try {
            const res = await userApi.loginUser(postData);
            dispatch({ type: LOGIN_SUCCESS, userData: res.data });
        } catch (e) {
            dispatch({ type: LOGIN_FAILURE });
            throw (e)
        }
    }
};

export const getLoggedUser = () => {
    return async dispatch => {
        dispatch({ type: GET_PROFILE_REQUEST });
        try {
            const res = await userApi.getLoggedUser();
            dispatch({ type: GET_PROFILE_SUCCESS, userData: res.data });
        } catch (e) {
            dispatch({ type: GET_PROFILE_FAILURE });
            throw (e)
        }
    }
};

export const registerUser = (postData) => {
    return async dispatch => {
        dispatch({ type: REGISTER_REQUEST });
        try {
            const res = await userApi.registerUser(postData);
            dispatch({ type: REGISTER_SUCCESS, userData: res });
        } catch (e) {
            dispatch({ type: REGISTER_FAILURE });
            throw (e)
        }
    }
};

export const updateProfile = (postData) => {
    return async dispatch => {
        dispatch({ type: UPDATE_PROFILE_REQUEST });
        try {
            const res = await userApi.updateProfile(postData);
            dispatch({ type: UPDATE_PROFILE_SUCCESS, userData: res });
        } catch (e) {
            dispatch({ type: UPDATE_PROFILE_FAILURE });
            throw (e)
        }
    }
};

export const contactRequest = (postData) => {
    return async dispatch => {
        dispatch({ type: CONTACT_REQUEST });
        try {
            const res = await userApi.contactRequest(postData);
            dispatch({ type: CONTACT_SUCCESS, userData: res });
        } catch (e) {
            dispatch({ type: CONTACT_FAILURE });
            throw (e)
        }
    }
};

export const getOrders = () => {
    return async dispatch => {
        dispatch({ type: GET_ORDER_REQUEST });
        try {
            const res = await userApi.getOrders();
            dispatch({ type: GET_ORDER_SUCCESS, userData: res });
        } catch (e) {
            dispatch({ type: GET_ORDER_FAILURE });
            throw (e)
        }
    }
};

export const getOrderDetails = (orderId) => {
    return async dispatch => {
        dispatch({ type: GET_ORDER_DETAILS_REQUEST });
        try {
            const res = await userApi.getOrderDetails(orderId);
            dispatch({ type: GET_ORDER_DETAILS_SUCCESS, userData: res });
        } catch (e) {
            dispatch({ type: GET_ORDER_DETAILS_FAILURE });
            throw (e)
        }
    }
};

export const getConfigration = () => {
    return async dispatch => {
        dispatch({ type: GET_CONFIG_REQUEST });
        try {
            const res = await userApi.getConfigration();
            dispatch({ type: GET_CONFIG_SUCCESS, userData: res });
        } catch (e) {
            dispatch({ type: GET_CONFIG_FAILURE });
            throw (e)
        }
    }
};

export const getPageContent = (slug) => {
    return async dispatch => {
        dispatch({ type: GET_PAGE_REQUEST });
        try {
            const res = await userApi.getPageContent(slug);
            dispatch({ type: GET_PAGE_SUCCESS, userData: res });
        } catch (e) {
            dispatch({ type: GET_PAGE_FAILURE });
            throw (e)
        }
    }
};

export const getAssignments = (data = '') => {
    if (data == '') {
        return async dispatch => {
            dispatch({ type: GET_ASSIGNMENT_REQUEST });
            try {
                const res = await userApi.getAssignments();
                dispatch({ type: GET_ASSIGNMENT_SUCCESS, userData: res });
            } catch (e) {
                dispatch({ type: GET_ASSIGNMENT_FAILURE });
                throw (e)
            }
        }
    } else {
        return async dispatch => {
            dispatch({ type: GET_ASSIGNMENT_REQUEST });
            try {
                const res = await userApi.getAssignments(data);
                dispatch({ type: GET_ASSIGNMENT_SUCCESS, userData: res });
            } catch (e) {
                dispatch({ type: GET_ASSIGNMENT_FAILURE });
                throw (e)
            }
        }
    }
};
