import { apiUrl } from './apiConfig';
class UserApi {
    static getHeader = () => {
        const currentAccessToken = localStorage.getItem('access_token');
        let authorizeBearer;
        if (currentAccessToken) {
            authorizeBearer = 'Bearer ' + currentAccessToken;
        } else {
            authorizeBearer = ' ';
        }
        const headers = new Headers();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', authorizeBearer);
        return headers;
    }

    static loginUser = async (postData) => {
        let loginData = {
            'grant_type' : 'password',
            'client_id' : 2,
            'client_secret' : 'K5oLBKBin9zDjooFBZMMcpJ7uR8rqU2dRHLW9baM',
            'email' : postData.email,
            'password' : postData.password,
            'scope' : '*',
        }

        try {
            const res = await fetch(`${apiUrl}login`, {
                method: 'post',
                headers: this.getHeader(),
                body: JSON.stringify(loginData)
            });
            return await res.json();
        } catch (e) {
            return { 'is_success': false, message: 'Something went wrong, please try again.' }
        }
    }

    static registerUser = async (postData) => {
        try {
            const res = await fetch(`${apiUrl}register`, {
                method: 'post',
                headers: this.getHeader(),
                body: JSON.stringify(postData)
            });
            return await res.json();
        } catch (e) {
            return { 'is_success': false, message: 'Something went wrong, please try again.' }
        }
    }

    static updateProfile = async (postData) => {
        try {
            const res = await fetch(`${apiUrl}update_profile`, {
                method: 'post',
                headers: this.getHeader(),
                body: JSON.stringify(postData)
            });
            return await res.json();
        } catch (e) {
            return { 'is_success': false, message: 'Something went wrong, please try again.' }
        }
    }

    static contactRequest = async (postData) => {
        try {
            const res = await fetch(`${apiUrl}register`, {
                method: 'post',
                headers: this.getHeader(),
                body: JSON.stringify(postData)
            });
            return await res.json();
        } catch (e) {
            return { 'is_success': false, message: 'Something went wrong, please try again.' }
        }
    }

    static getOrders = async () => {
        try {
            return fetch(`${apiUrl}getOrders`, {
                method: 'get',
                headers: this.getHeader()
            }).then((el) => {
                return el.json();
            })
        } catch (e) {
            return { 'is_success': false, message: 'Something went wrong, please try again.' }
        }
    }

    static getOrderDetails = async (orderId) => {
        try {
            return fetch(`${apiUrl}getOrder/${orderId}`, {
                method: 'get',
                headers: this.getHeader()
            }).then((el) => {
                return el.json();
            })
        } catch (e) {
            return { 'is_success': false, message: 'Something went wrong, please try again.' }
        }
    }

    static getConfigration = async () => {
        try {
            return fetch(`${apiUrl}getCongiration`, {
                method: 'get',
                headers: this.getHeader()
            }).then((el) => {
                return el.json();
            })
        } catch (e) {
            return { 'is_success': false, message: 'Something went wrong, please try again.' }
        }
    }

    static getLoggedUser = async () => {
        try {
            return fetch(`${apiUrl}loginUserDetails`, {
                method: 'get',
                headers: this.getHeader()
            }).then((el) => {
                return el.json();
            })
        } catch (e) {
            return { 'is_success': false, message: 'Something went wrong, please try again.' }
        }
    }

    static getPageContent = async(slug) => {
        try {
            return fetch(`${apiUrl}pageBySlug/${slug}`, {
                method: 'get',
                headers: this.getHeader()
            }).then((el) => {
                return el.json();
            })
        } catch (e) {
            return { 'is_success': false, message: 'Something went wrong, please try again.' }
        }
    }

    static getAssignments = async (data = '') => {
        if (data === '') {
            try {
                return fetch(`${apiUrl}getAssignments`, {
                    method: 'get',
                    headers: this.getHeader()
                }).then((el) => {
                    return el.json();
                })
            } catch (e) {
                return { 'is_success': false, message: 'Something went wrong, please try again.' }
            }
        } else {
            let key = Object.keys(data);            
            try {
                return fetch(`${apiUrl}getAssignments/${data[key[0]]}/${data[key[1]]}`, {
                    method: 'get',
                    headers: this.getHeader()
                }).then((el) => {
                    return el.json();
                })
            } catch (e) {
                return { 'is_success': false, message: 'Something went wrong, please try again.' }
            }
        }
    }
}
export default UserApi;