import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { loginUser } from '../../actions/user';
import { ToastsStore } from 'react-toasts';
import $ from "jquery";
import LoadingOverlay from 'react-loading-overlay';

class Login extends Component {
  state = {
    email: '',
    password: '',
    isLoading:false
  }

  componentWillMount() {
    if (localStorage.getItem('isLoggedIn')) {
      this.props.history.push("my-account");
    }
  }

  componentDidMount() {

  }

  onInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'email') {
      this.setState({ email: value })
    } else if (name === 'password') {
      this.setState({ password: value })
    }
  }

  handleSubmit = (e) => {
    this.setState({isLoading:true});
    e.preventDefault();
    let postData = {
      email: this.state.email,
      password: this.state.password
    }

    this.props.loginUser(postData).then(() => {
      if (this.props.user.is_success) {
        let userInfo = this.props.user;
        localStorage.setItem('isLoggedIn', true);
        localStorage.setItem('access_token', userInfo.token);
        localStorage.setItem('user', JSON.stringify(userInfo.user));
        $('#loggedUserName').html(userInfo.user.name);
        $('#logged-in').show();
        $('#not-logged-in').hide();
        ToastsStore.success(<span><b>Thank you ! </b><i>{this.props.user.message}</i></span>);
        setTimeout(() => {
          this.setState({isLoading:false});
          this.props.history.push("my-account");
        }, 3000);
      } else {
        ToastsStore.error(<span><b>Sorry ! </b><i>{this.props.user.message}</i></span>);
        this.setState({isLoading:false});
      }
    }).catch((e) => {
      ToastsStore.error(<span><b>Sorry ! </b><i>something went wrong.</i></span>);
      this.setState({isLoading:false});
    });
  }

  render() {
    return (
      <div>
        <section className="contact-section sp-one">
          <div className="container">
            <div className="row">
              <div className="col-md-offset-3 col-md-6 col-sm-12 col-xs-12 contact-column wow slideInRight animated">
                <LoadingOverlay
                  active={this.state.isLoading}
                  spinner
                  text='please wait...'
                >
                  <div className="contact-form-area">
                    <h3 className="text-center text-uppercase">Login Now</h3>
                    
                    <form onSubmit={this.handleSubmit} method="POST" id="contact-form" className="default-form">
                      <div className="row">
                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                          <input type="email" name="email" className="form-control" onChange={this.onInputChange} placeholder="Email Address" required />
                        </div>
                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                          <input type="password" name="password" className="form-control" onChange={this.onInputChange} placeholder="Password" required />
                        </div>
                        <div className="form-group col-md-12 col-sm-12 col-xs-12">
                          <button type="submit" className="btn btn-primary btn-lg btn-block" name="submit-form">Submit</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </LoadingOverlay>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.userReducer.result
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    loginUser
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));